import React, { useState } from "react";
import { cn } from "@/components/cn";
import { Button, Link } from "@nextui-org/react";
import Body from "@/components/typography/body";
import Subtitle from "@/components/typography/subtitle";
import Image from "next/image";
import config from "@/config";
import { Trans, TFunction } from "next-i18next";

const { appsURL } = config;

const StudioOverlay = ({ t, className }: { t: TFunction; className?: string }) => {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div
      className={cn("fixed bottom-8 left-1/2 w-full z-40 lg:w-3/4 -translate-x-1/2", { hidden: isHidden }, className)}
    >
      <div className="relative w-auto h-full m-4 p-0 lg:p-4 bg-black border border-primary rounded-xl">
        <Image
          src="/image/home/studio-overlay.svg"
          alt="Ava Protocol Studio"
          width={1000}
          height={1000}
          className="hidden lg:block absolute bottom-0 -right-8 w-3/5 h-auto max-w-none z-50 max-h-[140%]"
        />
        <div className="relative w-full h-full rounded-xl bg-grey-G4 overflow-hidden">
          {/* Gradient Background color overlay */}
          <div
            className="absolute inset-0 opacity-15"
            style={{
              background: "linear-gradient(90deg, rgba(61, 26, 255, 0.68) 0%, rgba(174, 78, 254, 0.60) 100%)",
            }}
          />
          <div className="pt-6 pb-4 px-6 flex justify-left flex-col text-left w-full lg:w-2/3">
            <Subtitle variant="large" as="h2" className="mb-2 z-60">
              <Trans
                i18nKey="title"
                t={t}
                components={{
                  strong: (
                    <span className="bg-gradient-to-r from-[#802CFD] to-[#F684FF] bg-clip-text text-transparent" />
                  ),
                }}
              />
            </Subtitle>
            <Body variant="large" className="mb-5 opacity-60 z-60">
              {t("description")}
            </Body>
            <div className="flex flex-row-reverse lg:flex-row gap-8 justify-between lg:justify-start w-full">
              <Button variant="bordered" color="primary" className="px-6" as={Link} href={appsURL}>
                <Body variant="large-strong">{t("cta")}</Body>
              </Button>
              <Button variant="bordered" className="px-6 opacity-60" onPress={() => setIsHidden(true)}>
                <Body variant="large-strong">{t("dismiss")}</Body>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioOverlay;
