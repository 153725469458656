import React, { useEffect, useState } from "react";
import _ from "lodash";
import Image from "next/image";
import { useLottie } from "lottie-react";
import { cn } from "@/components/cn";
import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import { SectionSpacing } from "@/utils";
import animationData from "@/assets/animation/tech-stack.json";
import { useMediaQueryWithUndefinedOnServer } from "@/components/useMediaQueryWithUndefinedOnServer";

interface InputItem {
  imgSrc: string;
  height?: number;
}

interface TranslatedItem {
  title: string;
  description: string;
  items: string[];
}

interface SolutionsProps {
  t: (key: string, options?: { returnObjects: boolean }) => any;
  items: InputItem[];
  className?: string;
}

function Solutions({ t, items, className }: SolutionsProps) {
  const isMobile = useMediaQueryWithUndefinedOnServer({ query: "(max-width: 768px)" });
  const translated = t("items") as TranslatedItem[];

  const combinedItems = _.map(translated, (row, index) => ({
    ...row,
    imgSrc: items[index]?.imgSrc,
    height: items[index]?.height ?? 280, // Set default height to 280 if not provided
  }));

  const [visibleItems, setVisibleItems] = useState([false, false, false]);

  const options = {
    animationData,
    loop: false,
    autoplay: true,
  };

  const { View: LottieView, animationItem } = useLottie(options);

  useEffect(() => {
    if (!animationItem) return;

    const onEnterFrame = () => {
      const currentFrame = animationItem.currentFrame;
      if (currentFrame >= 130 && !visibleItems[0]) {
        setVisibleItems([true, false, false]);
      }
      if (currentFrame >= 200 && !visibleItems[1]) {
        setVisibleItems([true, true, false]);
      }
      if (currentFrame >= 280 && !visibleItems[2]) {
        setVisibleItems([true, true, true]);
        animationItem.removeEventListener("enterFrame", onEnterFrame); // Remove the listener after all items are visible
      }
    };

    animationItem.addEventListener("enterFrame", onEnterFrame);

    return () => {
      animationItem.removeEventListener("enterFrame", onEnterFrame);
    };
  }, [animationItem, visibleItems]);

  return (
    <section className={cn("container relative", SectionSpacing, className)}>
      <div className="relative">
        <div className="flex flex-col text-center mb-4">
          <Title as="h3" variant="small" className="text-primary">
            {t("title")}
          </Title>
          <Title as="h2" variant="large">
            {t("description")}
          </Title>
        </div>
        {isMobile === undefined ? null : isMobile ? (
          <div className="flex flex-col justify-center">
            {_.map(combinedItems, (item, index) => (
              <div key={index} className="pt-12 pb-4">
                <div className="flex flex-col items-center">
                  <Image src={item.imgSrc} width={200} height={200} alt={item.title} className="mb-6" />
                  <Title as="h3" variant="small" className="mb-2">
                    {item.title}
                  </Title>
                  <Body as="p" variant="large" className="text-center">
                    {item.description}
                  </Body>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center relative overflow-hidden h-[708px] 2xl:h-[772px]">
            {/* Animation layer */}
            <div className="w-[367px] 2xl:w-[400px] h-full">{LottieView}</div>
            {/* Text layer */}
            <div className="absolute grid grid-cols-3 h-full">
              {_.map(combinedItems, (item, index) => (
                <div
                  key={item.title}
                  className={cn(
                    "w-full",
                    {
                      "row-start-1 col-start-3": index === 0,
                      "row-start-2 col-start-1": index === 1,
                      "row-start-3 col-start-3": index === 2,
                      "flex-row-reverse": index % 2 === 1,
                      "animate-fade-in": visibleItems[index],
                      "opacity-0": !visibleItems[index],
                    },
                    "flex items-center gap-4",
                  )}
                >
                  <div className={cn("relative min-w-12 grow flex")}>
                    <div className="flex-grow h-px">
                      <div
                        className={cn("absolute w-16 h-px bg-white", index % 2 === 0 ? "-left-4" : "-right-4")}
                      ></div>
                    </div>

                    <div
                      className={cn(
                        "w-2.5 h-2.5 bg-white rounded-full absolute transform ",
                        index % 2 === 0
                          ? "right-0 translate-x-1/2 -translate-y-1/2"
                          : "left-0 -translate-x-1/2 -translate-y-1/2",
                      )}
                    ></div>
                  </div>
                  <div className="w-[320px] text-left">
                    <Title as="h3" variant="small" className="mb-2">
                      {item.title}
                    </Title>
                    <Body as="p" variant="large">
                      {item.description}
                    </Body>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Background texture */}
      <div className="hidden sm:block absolute w-[3px] h-[4px] right-[100px] top-1/2">
        <Image src="/image/home/solutions/group-657.svg" width={3} height={4} alt="stars" />
      </div>
    </section>
  );
}

export default Solutions;
