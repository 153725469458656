"use client";

import React from "react";
import _ from "lodash";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Spacer, Link } from "@nextui-org/react";

import { cn } from "@/components/cn";
import Title from "@/components/typography/title";
import Card from "@/components/card";
import { useMediaQuery } from "react-responsive";

type Backer = {
  imgSrc: string;
  name: string;
  width: number;
  height: number;
  className?: string;
};

const data: Backer[] = [
  {
    imgSrc: "/image/home/backers/greylock.png",
    name: "Greylock",
    width: 161,
    height: 40,
  },
  {
    imgSrc: "/image/home/backers/gsr.png",
    name: "GSR",
    width: 145,
    height: 41,
  },
  {
    imgSrc: "/image/home/backers/bcvc.svg",
    name: "Bloccelerate VC",
    width: 239.64626,
    height: 42,
  },
  {
    imgSrc: "/image/home/backers/taisu.png",
    name: "Taisu Ventures",
    width: 168.74947,
    height: 30,
  },
  {
    imgSrc: "/image/home/backers/electric.png",
    name: "Electric Capital",
    width: 373.3344,
    height: 28,
  },
  {
    imgSrc: "/image/home/backers/foundation.png",
    name: "Foundation Capital",
    width: 220,
    height: 70.4,
  },
];

const mobileData: Backer[] = [
  {
    imgSrc: "/image/home/backers/greylock.png",
    name: "Greylock",
    width: 145,
    height: 36,
    className: "basis-1-2-minus-1.5rem",
  },
  {
    imgSrc: "/image/home/backers/gsr.png",
    name: "GSR",
    width: 145,
    height: 41,
    className: "basis-1-2-minus-1.5rem",
  },
  {
    imgSrc: "/image/home/backers/electric.png",
    name: "Electric Capital",
    width: 320,
    height: 24,
  },
  {
    imgSrc: "/image/home/backers/foundation.png",
    name: "Foundation Capital",
    width: 200,
    height: 64,
  },
  {
    imgSrc: "/image/home/backers/bcvc.svg",
    name: "Bloccelerate VC",
    width: 210,
    height: 36.80398,
  },
  {
    imgSrc: "/image/home/backers/taisu.png",
    name: "Taisu Ventures",
    width: 157.4993,
    height: 30,
  },
];

export default function Component({ className }: { className?: string }) {
  const { t } = useTranslation("home");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <section className={cn("container", className)}>
      <div className="my-auto flex flex-col relative">
        {/* Background texture */}
        <div
          className="hidden sm:block rounded-[100px] opacity-20 blur-[125px] w-[976px] h-[344px] absolute top-[100px] left-[100px] pointer-events-none"
          style={{ background: "linear-gradient(90deg, #802CFD 0%, #F684FF 100%)" }}
        ></div>
        <div className="hidden sm:block absolute rounded-[100px] bg-[#3D1AFF] blur-[110px] w-[358px] h-[55px] top-[236px], left-[336px]" />
        <Image
          className="hidden sm:block absolute left-[200px] top-[106px] pointer-events-none"
          src="/image/home/backers/circles.svg"
          width={680}
          height={680}
          alt="bg-circles"
        />
        <Image
          className="hidden sm:block absolute top-[106px] left-[120px] pointer-events-none"
          src="/image/home/backers/stars.svg"
          width={904}
          height={219}
          alt="bg-stars"
        />
        <Image
          className="hidden sm:block absolute left-[-100px] top-[236px] pointer-events-none"
          src="/image/home/backers/floating-box.svg"
          width={67}
          height={66}
          alt="bg-floating-box"
        />
        <Image
          className="hidden sm:block absolute right-[-100px] bottom-[-30px] pointer-events-none"
          src="/image/home/backers/floating-box.svg"
          width={67}
          height={66}
          alt="bg-floating-box"
        />
        <div className="text-center">
          <Title as="h2" variant="large">
            {t("backers.title")}
          </Title>
        </div>
        <Spacer y={14} />

        {/* Section content */}
        <div className="relative flex flex-wrap gap-4 md:gap-8">
          {_.map(isSmallScreen ? mobileData : data, ({ name, imgSrc, width, height, className }) => (
            <Card
              key={name}
              className={cn(
                "flex justify-center items-center h-24 md:h-48 grow",
                "px-8 py-4 md:px-10 md:py-10",
                className,
              )}
            >
              <Image src={imgSrc} alt={`${name} logo`} width={width} height={height} />
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}
